import { Box, Button, CircularProgress, TableFooter } from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import { PricingRow } from "./PricingRow";
import { PricingTableHeader } from "./PricingTableHeader";
import { Action, Pricing } from "../../pricingListSlice";
import { memo, useCallback, useMemo, useState } from "react";
import { usePricingSelection } from "../../hooks/actions/pricingSelection.hook";
import { Tender } from "../../../tender_page/tender.module";
import { ReadCommentModal } from "../ReadCommentModal";
import { If } from "../../../../common/components/If";
import { donwloadPricingPbInputs, downloadPricingExport } from "../../pricingList.thunk";
import { useAppDispatch } from "../../../../common/hooks/default";
import { apiGet } from "../../../../common/utils/request.utils";
import { saveObjectAsJSON } from "../../../../common/utils/downloadJson";
import { TableVirtuoso } from "react-virtuoso";
import usePrevious from "../../../../common/hooks/usePrevious";
import { StatelessFlashUpdateProvider } from "../../../../common/components/StatelessFlashUpdateTableCell";
import GraphModal from "../actionModal/GraphModal";
import { Column } from "../../../../common/components/ColumnsSelector/ColumnsSelector";
import { ColumnIds } from "../../hooks/columns/common";

interface PricingTableProps {
	tender?: Tender;
	pricings: Pricing[] | null | undefined;
	valueOrPercent: "value" | "percent";
	hasNextPage: boolean;
	nextPage: (page: number) => void;
	currentPage: number;
	loading: boolean;
	actOnPricings: (
		action: Action,
		pricingIds?: number[],
		callback?: () => void
	) => void;
	useInfiniteScroll?: boolean;
	height?: string;
	selectedColumns?: Column<ColumnIds>[];
}

const style = {
	table: {
		borderSpacing: "0px 0px",
		maxHeight: "100%",
	},
	tableWrapper: {
		height: "100%",
	},
	wayPoint: {
		height: 10,
	},
};

function PricingTableComponent(props: PricingTableProps) {
	const {
		pricings,
		valueOrPercent,
		hasNextPage,
		nextPage,
		currentPage,
		loading,
		actOnPricings,
		useInfiniteScroll = true,
		height,
		selectedColumns,
	} = props;
	const [comment, setComment] = useState<string | undefined>(undefined);
	const [isScrolling, setIsScrolling] = useState(false);
	const [openRows, setOpenRows] = useState<Record<number, boolean>>({});
	const wasOpenRows = usePrevious(openRows);
	const [graphModalOpen, setGraphModalOpen] = useState(false);
	const [selectedPricing, setSelectedPricing] = useState<Pricing | undefined>(
		undefined
	);
	const displayComment = useCallback(
		(status_comment: string) => {
			setComment(status_comment);
		},
		[setComment]
	);

	const submitStatusUpdate = useCallback(
		(id: number, action: Action) => {
			actOnPricings(action, [id]);
		},
		[actOnPricings]
	);

	const {
		availableActions,
		selectedIds,
		selectedRunIds,
		togglePricingSelection,
		clearSelectedPricings,
		selectAll,
	} = usePricingSelection(pricings);

	const loadMore = useCallback(() => {
		// ony used by virtualization
		if (useInfiniteScroll && !loading && hasNextPage) {
			nextPage(currentPage + 1);
		}
	}, [
		pricings,
		loading,
		hasNextPage,
		nextPage,
		currentPage,
		useInfiniteScroll,
	]);
	const dispatch = useAppDispatch();

	const onDownloadExcel = useCallback(
		(ids: number[]) => {
			dispatch(downloadPricingExport(ids));
		},
		[dispatch]
	);

	const onDownloadPbParams = useCallback(
		(pricingRunId: number) => {
			dispatch(donwloadPricingPbInputs(pricingRunId))
		},
		[dispatch]
	)

	const onDownloadJson = useCallback(
		(id: number) => {
			apiGet(`pricing-run?pricing_run_id=${id}`).then((response) => {
				saveObjectAsJSON(`${id}-inputs`, response.data);
			});
		},
		[dispatch]
	);

	const onGlobalAction = useCallback(
		(action: Action) => {
			actOnPricings(action, selectedIds, clearSelectedPricings);
		},
		[actOnPricings, selectedIds, clearSelectedPricings]
	);

	const openGraphModal = (pricing: Pricing | undefined) => {
		setGraphModalOpen(true);
		setSelectedPricing(pricing);
	};

	const header = useMemo(
		() => (
			<PricingTableHeader
				selectedColumns={selectedColumns}
				selectedRunIds={selectedRunIds}
				clearPricingSelection={clearSelectedPricings}
				availableActionsForSelection={availableActions}
				selectAll={selectAll}
				onAction={onGlobalAction}
				onDownloadExcel={onDownloadExcel}
			/>
		),
		[
			selectedRunIds,
			clearSelectedPricings,
			availableActions,
			selectAll,
			onGlobalAction,
			onDownloadExcel,
			selectedColumns,
		]
	);

	const footer = useMemo(
		() => (
			<>
				<TableFooter>
					<TableRow
						key={"loader"}
						sx={{
							"&:last-child td, &:last-child th": {
								border: 0,
							},
							"& > td": { padding: "0px" },
						}}
					></TableRow>
				</TableFooter>
				{loading && <CircularProgress color="primary" />}
				<If condition={!useInfiniteScroll && !loading}>
					<Button
						variant="text"
						onClick={() => nextPage(currentPage + 1)}
					>
						Load More
					</Button>
				</If>
			</>
		),
		[loading, useInfiniteScroll, nextPage]
	);

	const Row = useCallback(
		(index: number, pricingRow: Pricing) => (
			<PricingRow
				selectedColumns={selectedColumns}
				isScrolling={isScrolling}
				key={pricingRow.id}
				isSelected={selectedIds.includes(pricingRow.id)}
				toggleSelection={togglePricingSelection}
				valueOrPercent={valueOrPercent}
				onSubmit={submitStatusUpdate}
				onDownloadExcel={onDownloadExcel}
				onDownloadPbParams={onDownloadPbParams}
				onDownloadJson={onDownloadJson}
				statusIsLoading={pricingRow?.isStatusUpdating}
				pricing={pricingRow}
				onDisplayComment={displayComment}
				isOpen={!!openRows[pricingRow.id]}
				wasOpen={!!wasOpenRows?.[pricingRow.id]}
				setIsOpen={(newIsOpen) =>
					setOpenRows((prev) => ({
						...prev,
						[pricingRow.id]: newIsOpen,
					}))
				}
				openGraphModal={openGraphModal}
				openWarningModal={displayComment}
			/>
		),
		[
			isScrolling,
			selectedIds,
			togglePricingSelection,
			valueOrPercent,
			submitStatusUpdate,
			onDownloadExcel,
			onDownloadPbParams,
			onDownloadJson,
			displayComment,
			openRows,
			wasOpenRows,
			setOpenRows,
			selectedColumns,
		]
	);

	const TableComponent = (tprops: any) => (
		<Table
			{...tprops}
			component={"table"}
			stickyHeader
			size="small"
			sx={style.table}
			aria-label="sticky table"
			style={{ borderCollapse: "separate" }}
		/>
	);
	return (
		<StatelessFlashUpdateProvider>
			<Box sx={height ? { height } : style.tableWrapper}>
				<TableVirtuoso
					style={{ height: "100%", minHeight: "400px" }}
					data={pricings || []}
					components={{ Table: TableComponent }}
					context={props}
					endReached={loadMore}
					fixedFooterContent={() => footer}
					fixedHeaderContent={() => header}
					itemContent={Row}
					isScrolling={setIsScrolling}
				/>
				<ReadCommentModal
					isOpen={!!comment}
					comment={comment}
					onClose={() => setComment(undefined)}
				/>
				<If condition={graphModalOpen && selectedPricing}>
					<GraphModal
						open={graphModalOpen}
						onClose={() => setGraphModalOpen(false)}
						pricing={selectedPricing}
					/>
				</If>
			</Box>
		</StatelessFlashUpdateProvider>
	);
}

export const PricingTable = memo(PricingTableComponent);
