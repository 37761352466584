import { Box, CircularProgress } from "@mui/material";
import { HamburgerMenu } from "../../../../common/components/HamburgerMenu";
import { Action, Pricing } from "../../pricingListSlice";
import {
	STATUS_CANNOT_EXPORT_INPUT,
	STATUS_CANNOT_EXPORT_RESULT,
} from "../../pricingList.constant";
import { HamburgerOptions } from "../../../../common/components/HamburgerOptions";
import { formatError } from "../../../../common/utils/formatError";
import { useMemo } from "react";

export interface PricingHamburgerProps {
	pricing: Pricing;
	isExportLoading: boolean;
	submitAction: (id: number, action: Action) => void;
	onExportExcel: () => void;
	onExportJson: () => void;
	onExportPbParams: () => void;
}

const EMPTY_ARRAY: any[] = [];

export const PricingHamburger = (props: PricingHamburgerProps) => {
	const {
		pricing,
		submitAction,
		onExportExcel,
		onExportJson,
		onExportPbParams,
		isExportLoading,
	} = props;

	const pricingActions = useMemo(
		() =>
			(pricing.actions || []).map((action) => (
				<HamburgerOptions
					testId={`action-${action.action}`}
					key={action.endpoint}
					title={action.display_name}
					action={() => submitAction(pricing.id, action)}
				/>
			)),
		[pricing, submitAction]
	);

	const printAction = useMemo(() => {
		const pricingRun = pricing?.pricing_runs[0];
		const actions = []
		if (!isExportLoading) {
			if (!STATUS_CANNOT_EXPORT_RESULT.includes(pricingRun?.status)) {
				actions.push(<HamburgerOptions
					key={"export"}
					title={"Export to excel"}
					action={onExportExcel}
				/>)
			} 
			
			if (!STATUS_CANNOT_EXPORT_INPUT.includes(pricingRun?.status)) {
				actions.push(<HamburgerOptions
					key={"export_JSON"}
					title={"Export JSON"}
					action={onExportJson}
				/>)
			}

			if(pricingRun?.input_params_s3_filename){
				actions.push(<HamburgerOptions
					key={"export_pb_params"}
					title={"Export powerbridge params"}
					action={onExportPbParams}
				/>)
			}
		}
		return actions;
	}, [pricing?.pricing_runs[0], onExportExcel, onExportJson]);

	const loader = useMemo(
		() =>
			isExportLoading
				? [
						<Box
							key={"loader"}
							sx={{
								display: "flex",
							}}
						>
							<CircularProgress size={20} />
						</Box>,
				  ]
				: EMPTY_ARRAY,
		[isExportLoading]
	);

	const mailToAction = useMemo(() => {
		if (pricing?.pricing_runs?.[0]?.is_pb_error) {
			const runId = pricing?.pricing_runs[0]?.id;
			const error = pricing?.pricing_runs?.[0]?.error;
			let link = "mailto:powertrading-ispowerfeatureteam.gem@engie.com?";
			link +=
				"subject=" + encodeURIComponent(`Error from gepo [${runId}]`);
			link +=
				"&body=" +
				encodeURIComponent(
					`We received an error from powerbridge when running pricing run with id ${runId}: \n\n` +
						formatError(error)
				);
			return [
				<HamburgerOptions
					key={"report-pb-error"}
					title="Report error to powebridge"
					action={() => window.open(link)}
				/>,
			];
		}
		return EMPTY_ARRAY;
	}, []);

	const RenderedChildren = useMemo(
		() => [...pricingActions, ...printAction, ...loader, ...mailToAction],
		[pricingActions, printAction, loader, mailToAction]
	);

	return RenderedChildren.length > 0 ? (
		<HamburgerMenu>{RenderedChildren}</HamburgerMenu>
	) : (
		<></>
	);
};
